const { FacebookClientId } = require('../../config/social-connect');

const initFacebook = () => {
	window.fbAsyncInit = function () {
		window.FB.init({
			appId: FacebookClientId,
			xfbml: true,
			cookie: true,
			version: 'v8.0',
		});
	};

	(function (d, s, id) {
		var js,
			fjs = d.getElementsByTagName(s)[0];
		if (d.getElementById(id)) {
			return;
		}
		js = d.createElement(s);
		js.id = id;
		js.src = '//connect.facebook.net/en_US/sdk.js';
		fjs.parentNode.insertBefore(js, fjs);
	})(document, 'script', 'facebook-jssdk');
};

module.exports = {
	initFacebook,
};
