<template>
	<LoginComponent />
</template>

<script>
import LoginComponent from '@/components/Login.vue';

export default {
	name: 'Login',
	components: { LoginComponent },
};
</script>
